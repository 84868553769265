import React from 'react'
import './navbar.css';

import { Link } from 'react-router-dom';
import logo from '../../assets/white-tag-logo.png';


const Menu = () =>
{
  <>
      <div className='main-navbar-links-container' data-visible="false">
          <p><a href="https://forms.gle/VzyyXRcGhgxtjxwT8">Invite Us</a></p>
          <p><a href="https://forms.gle/H8DnqkpNw4Vs8KNBA">Partner</a></p>
          <p><Link to='/activities' target='_top' >Activities</Link></p>
          {/* Activities and Membership pages are to be created where the registration page is the membership page and a separate activities page will be created where in all the programs we are doing will be displayed */}
          <p><a href='https://forms.gle/Cra418H8BvswHurDA'>Membership</a></p>

            <div className='main-navbar-btn'>
              <a href="https://discord.gg/xdvK5YEKTk">Discord</a>
            </div>
            
      </div>
    
  </>
  
}


const Navbar = () => {
  const primaryNav = document.getElementsByClassName('main-navbar-links-container');
  // const linetoggle = document.querySelector(".loader-line");

const navToggle = document.querySelector(
  ".main-navbar-menu-toggle , .loader-line"
  );
  

return (
    <div className='main-navbar padding'>
      <div className='main-navbar-links'>

        <div className='main-navbar-links-logo'>
          <Link to='/' target='_top'  ><img src={logo} alt="logo" /></Link>
        </div>
        <div>
          <button className='main-navbar-menu-toggle' id='main-navbar-links-container' alt="menu" aria-controls='main-navbar-links-container' aria-expanded="false">
          </button>
          
        </div>
        
        <Menu />
      </div>
    </div>
  )
  

}

  
 


export default Navbar