import React from 'react'
import './hero.css';
import white_logo from '../../assets/white-name-logo.png';

const Homehero = () => {
  return (
    <div className='hero grid margin'>
      
      <div className='hero-head'>
        
        <div className='hero-logo-container hidden'>
          <img className='hero-logo-img' src={white_logo} alt="Logo"/>
        </div>

        <div className='hero-heading hidden'>
          <div className='hero-heading-text-container'>

            <div className='hero-heading-sm'>
              <h3 className='hero-heading-text-sm'>Of the</h3>
              <h3 className='hero-heading-text-sm'>By the</h3>
              <h3 className='hero-heading-text-sm'>For the</h3>
            </div>

            <div className='hero-heading-lg'>
              <h1 data-value="Community" className='hero-heading-text-lg or-gradient-text'>Community</h1>
            </div>

          </div>
        </div>

        <p className='hero-p'>
A platform for Relentless Learners, Entrepreneurs and Corporates.
        </p>
      </div>
      
      <a href='https://forms.gle/Cra418H8BvswHurDA' className='sqr-button hidden'>Sign Up</a>
      <div aria-expanded="false" className="loader-line"></div>
      <p className='btm-statement hidden'>2023 - Year of Inception</p>

    </div>
  )
}

export default Homehero