import React from 'react'
import './partner.css';
import partner_img from "../../assets/partner-img.png";
import white_arrow from "../../assets/arrow-white.svg";

const Homeaspartner = () => {
  return (

    <div className="dark-window padding">
      <div className="flexbox">
        <div className="dark-illustration-bg hidden">
          <img
            className="illustration-img"
            src={partner_img}
            alt="illustration"
          />
        </div>

        <div className="content-text-box">
          <p className="dark-section-tag">PARTNER</p>

          <h2 className="dark-section-heading">
            Being a{" "}
            <span className="dark-section-heading or-gradient-text">
              Partner
            </span>
          </h2>

          <p className="dark-section-para-1">
          What goes around is what comes around.
          </p>

          <p className="dark-section-para-2">
          As a Professional partner you get the benefits of our reach and connections as you can find people who belong to similar field as you thus increasing your reach and mutually sharing knowledge, experience and resources with one another.
          </p>

          <div className="button hidden">
            <a className="button-text" href="https://forms.gle/969vS5YefVbXynyQ8">
              Connect <img src={white_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Homeaspartner