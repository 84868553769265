import React from 'react'
import './home.css';

import { Homeabout , Homecommunity, Homeasent, Homeevents, Homehero, Homeaspartner, Homeasstudent } from '../../sections';

const Home = () => {
    return(
        <>
        <Homehero />
        <Homeabout />
        <Homecommunity />
        <Homeevents />
        <Homeasstudent />
        <Homeasent />
        <Homeaspartner />
        </>
    )
}

export default Home