import React from 'react';
import './ent-page-ncg.css';

import ent_page_ncg_img from '../../assets/ent-page-ncg-img.png';

const Entpagencg = () => {
  return (
    <div className="dark-window padding">
      
        <div className="flexbox">
          <div className="illustration-bg-nobox hidden">
            <img
              className="illustration-img"
              src={ent_page_ncg_img}
              alt="illustration"
            />
          </div>
  
          <div className="content-text-box">
  
            <h2 className="dark-section-heading">
             Network, Collab, {" "}
              <span className="dark-section-heading or-gradient-text">
                Grow
              </span>
            </h2>
  
            <p className="dark-section-para-1">
            To grow as an entrepreneur you need better resources. Better resources demand better network.
            </p>

            
            <p className="dark-section-para-2">
              Meet people from similar domain and connect, share resources and services and grow together. Our events facilitate meets for entrepreneurs from all our E-Cell partners spanning across Hyd, thus providing a platform to Network, Collab and Grow.
            </p>

          </div>
        </div>
      </div>
  )
}

export default Entpagencg
