import React from 'react'
import './about.css';
import about_img from "../../assets/about-img.png";


const Homeabout = () => {
  return (
<div id='about' className='window padding'>
  <div className='flexbox'>

      <div className='illustration-bg hidden'>
        <img className='illustration-img' src={about_img} alt="illustration" />
      </div>


      <div className='content-text-box'>

        <p className='section-tag'>ABOUT US</p>

        <h2 className='section-heading'>A platform for <span className='section-heading or-gradient-text'>everyone</span></h2>

        <p className='section-para-1'>From innovation to networking, learning to implementing, we help you with solutions to all problems at one single platform.</p>
  
        <p className='section-para-2'>We identify the problems faced by students, entrepreneurs, startups and bring up solutions that focus to solve the problem from the fundamentals.</p>

      </div>

    </div>

  </div>

  

  )
}

export default Homeabout