import React from 'react'
import './events.css';
import events_img from '../../assets/events-rocket-img.png';
import dark_arrow from "../../assets/arrow-black.svg";

const Homeevents = () => {
  return (
    <div id='events' className='window padding'>
      <div className='events-flexbox'>
            <p className='section-tag'>EVENTS</p>
            <h2 className='section-heading'>Get. Set. Grow.</h2>
        <div className='events-content-box'>

          <div className='events-content-text'>
            <p className='section-para-1'>It's just Get -&gt; Set -&gt; Grow</p>
            <p className='btn-line'>Get notification about next event 🔔</p>
            <div className="button hidden">
            <a className="dark-button-text" href="https://forms.gle/W16Nop5542YMasvf6">
              Notify Me <img src={dark_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>
          </div>
          </div>
          
          <img className='events-rocket-img hidden' src={events_img} alt="illustration" />

        </div>

        <div className='events-cards-flexbox hidden'>
          <div className='events-card hidden'>
            <h2 className='events-card-heading'>🎟️ Get</h2>
            <p className='events-card-desc'>Events and Meets are regular at Bharatanve. Get to attend the meets & events that are aimed to provide you tons of knowledge & experience.</p>
          </div>
          <div className='events-card hidden transformY '>
            <h2 className='events-card-heading'>🤝 Set</h2>
            <p className='events-card-desc'>Build your network with people who share similar interests as you, share knowledge, experience and connect for future prospects.</p>
          </div>
          <div className='events-card transformY2 hidden'>
            <h2 className='events-card-heading'>🚀 <span className='events-card-heading or-gradient-text'>Grow</span></h2>
            <p className='events-card-desc'>Implement what you learned at the events, meet ups & enjoy the post benefits like better network, reach, resources which are the end results.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homeevents