import React from 'react';
import './ent-page-hero.css';

import ent_img from "../../assets/entrepreneur-img.png";
import white_arrow from "../../assets/arrow-white.svg";


const Entpagehero = () => {
  return (
    <div className="dark-window padding">
      
        <div className="flexbox">
          <div className="illustration-bg-nobox hidden">
            <img
              className="illustration-img"
              src={ent_img}
              alt="illustration"
            />
          </div>
  
          <div className="content-text-box">
  
            <h2 className="dark-section-heading">
              For {" "}
              <span className="dark-section-heading or-gradient-text">
                Entrepreneurs
              </span>
            </h2>
  
            <p className="dark-section-para-1">
              Being an Entrepreneur is no easy task.
            </p>
  
            <p className="dark-section-para-2">
            Bharatanve's startup community is a platform where young entrepreneurs meet, connect and explore new dimensions of Entrepreneurship so you as an Young Entrepreneur can grow..
            </p>


            <div className="button hidden">
            <a className="button-text" href='https://forms.gle/KYo92LYT3UJMX1Z68' >
              Inviting E-Cells across HYD <img src={white_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>

          </div>

          </div>
        </div>
      </div>
  )
}

export default Entpagehero
