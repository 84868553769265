import React from 'react';
import './students-hero.css';

import { BrowserRouter as Link } from 'react-router-dom';
import student_img from "../../assets/student-img.png";
import white_arrow from "../../assets/arrow-white.svg";

const Studentshero = () => {
    return(
        <div id='students-hero' className="dark-window padding">
      
        <div className="flexbox">
          <div className="illustration-bg-nobox hidden">
            <img
              className="illustration-img"
              src={student_img}
              alt="illustration"
            />
          </div>
  
          <div className="content-text-box">
            <p className="dark-section-tag">STUDENT</p>
  
            <h2 className="dark-section-heading">
              Learn like a {" "}
              <span className="dark-section-heading or-gradient-text">
                Pro..
              </span>
            </h2>
  
            <p className="dark-section-para-1">
            Students are our top priority and we understand your problems.
            </p>
  
            <p className="dark-section-para-2">
            At Bharatanve., we provide you a better approach towards learning which will help you choose your career with a clarity. For this, we have lots of events, seminars, we provide with resources, mentors, internships & lot more.
            </p>
  
            {/* <p id='students-hero-button-desc' className="dark-button-desc">
              {" "}
              📜 Invite us as{" "}
              <a className="dark-btn-line-link" href='/'>
                campus representative.
              </a>{" "}
              ﹖
            </p> */}

            <div id='students-hero-btn' className="button hidden">
            <a className="button-text" href='https://forms.gle/KYo92LYT3UJMX1Z68' >
              Invite Us <img src={white_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>
          </div>

          </div>
        </div>
      </div>
    )
}

export default Studentshero