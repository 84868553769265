import React from 'react'
import './community.css';
import community_title from "../../assets/community-title.png";
import community_graph from "../../assets/community-graph.png";
import dark_arrow from "../../assets/arrow-black.svg";

const Homecommunity = () => {
  return (
    <div id='community' className='window padding'>
      <div className='community-flexbox'>
        <img className='community-title hidden' src={community_title} alt="Bharatanve Community" />
        <p className='community-tag'>This is our first year in the making.</p>
        
        <img className='community-graph' src={community_graph} alt="graph" />
        <div className='community-stats-container'>
          <div className='community-stats-flexbox'>
            <div className='community-stats-box hidden'>
              <p className='community-stats-box-num'>1500+</p>
              <p className='community-stats-box-num-desc'>Student<br/>Network</p>
            </div>
            <div className='community-stats-box hidden'>
              <p className='community-stats-box-num'>1</p>
              <p className='community-stats-box-num-desc'>Campus<br/>Partners</p>
            </div>
            <div className='community-stats-box hidden'>
              <p className='community-stats-box-num'>3</p>
              <p className='community-stats-box-num-desc'>Events</p>
            </div>
            <div className='community-stats-box hidden'>
              <p className='community-stats-box-num'>2</p>
              <p className='community-stats-box-num-desc'>Exp.<br/>Programs</p>
            </div>
            <div className='community-stats-box hidden'>
              <p className='community-stats-box-num'>10</p>
              <p className='community-stats-box-num-desc'>Expert<br/>Partners</p>
            </div>
          </div>
        </div>
        <div className="button hidden">
            <a className="dark-button-text" href="https://forms.gle/Cra418H8BvswHurDA">
               Join Community <img src={dark_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>
          </div>
      </div>
    </div>
  )
}

export default Homecommunity