import React from 'react'
import './error-component.css';

import {Link} from 'react-router-dom';
import error_img from '../../assets/error-page-img.png';
import white_arrow from "../../assets/arrow-white.svg";

const ErrorComponent = () => {
  return (
    <div id='about' className='dark-window padding'>
  <div className='flexbox'>

        <div className='illustration-bg-nobox hidden'>
          <img className='illustration-img' src={error_img} alt="illustration" />
        </div>

      <div className='content-text-box'>


        <p id='error-component-tag' className='dark-section-tag'>ERROR 404</p>

        <h2 id='error-component-heading' className='dark-section-heading'>Page Not Found</h2>

        <p id='error-component-para-main' className='dark-section-para-1'>Oops...! Looks like you are on the wrong page.</p>
  
        <p id='error-component-para-sub' className='dark-section-para-2'>There might have been a typo in the URL, please check and try again.</p>

        <div id='error-component-button' className="button hidden">
            <Link to="/" target='_top' className="button-text" >
              Back to Home <img src={white_arrow} alt="arrow" />
            </Link>
            <div className="button-underline"></div>
          </div>

      </div>

    </div>

  </div>
  )
}

export default ErrorComponent
