import React from 'react'
import './privacy.css';

const Privacy = () => {
    return(
        <>
        Bharatanve.'s privacy policy page
        </>
    )
}

export default Privacy