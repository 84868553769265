import React from 'react'
import './activities.css';
import ecell_logo from '../../assets/E-Cell Logo.png';
import white_arrow from "../../assets/arrow-white.svg";

const Activities = () => {
  return (
    <div className="dark-window padding">
      <div className="flexbox activities-box">

        <div className='illustration-bg-nobox hidden'>
          <img id='E-Cell-LFDC-Logo' src={ecell_logo} alt="illustration" className="illustration-img" />
        </div>

        <div className="content-text-box">
          <div className="activity-box">
            <p className="dark-section-sub-heading"><span className='or-gradient-text'>Community Partner</span> of E-Cell LFDC</p>
            <p className="dark-section-para-1">To achieve our purpose have partnered with our first ever E-Cell Partner from Little Flower Degree & PG College, Uppal, Hyd.</p>
            <p className="dark-section-para-2">Our purpose is to help boost the Nation's Startup Ecosystem and encourage the Entrepreneural Spirit in today's generation.</p>
            <p className="dark-section-para-2">Bharatanve. is the Community Partner for E-Cell LFDC, thus helping the E-Cell to nuture Entrepreneurship in the campus by providing the students a platform where they get exposure to new generation Entrepreneurs and Startups.</p>
            <p className="dark-section-para-2">We also welcome the startups nurtred in E-Cell LFDC to become the part of Startup Community of Bharatanve. and use our resources that we provide for Entrepreneur partners of our Community. </p>

            <div className="button hidden">
          <a href='https://forms.gle/MgXakkkgbMcs1y2M9' target='_top' className="button-text">
            Join E-Cell <img src={white_arrow} alt="arrow" />
          </a>
          <div className="button-underline"></div>
        </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activities
