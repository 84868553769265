import React from 'react'
import { ErrorComponent } from '../../components';
import './error-404.css';

const Error404 = () => {
  return (
    <>
    <ErrorComponent />
    </>
  )
}

export default Error404
