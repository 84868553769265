import React from 'react';
import './students-page.css';

import { Studentshero, Studentscprmodel, Studentsthinkbox, Studentsexploreevents } from '../../sections';

const Students = () => {
    return(
        <>
           <Studentshero />
           <Studentscprmodel />
           <Studentsthinkbox />
           <Studentsexploreevents />
        </>
    )
}

export default Students