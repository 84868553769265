import React from 'react'
import './footer.css';
import footer_logo from "../../assets/default-name-logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer padding">
      <div className='footer-flexbox'>
        <div className='footer-content'>
        <div className="footer-logo-bar">
          <img className='footer-logo' src={footer_logo} alt="Logo" />
          <h2 className='footer-title'>Get. Set.{" "}<span className='footer-title or-gradient-text'>Grow.</span></h2>
        </div>

        <div className="footer-links-flexbox">
            <div className='footer-links-box'>
              <h3 className='footer-links-category-title'>Company</h3>
              <a className='footer-link' href="#about">About Us</a>
              <a className='footer-link' href="https://forms.gle/Cra418H8BvswHurDA">Community</a>
              <a className='footer-link' href="mailto: namaste@bharatanve.com">Reach Us</a>
              <a className='footer-link' href="https://rushikeshshastri.github.io/codeincredibles/">Code Incredibles.</a>
            </div>
            <div className='footer-links-box'>
              <h3 className='footer-links-category-title'>Community</h3>
              <a className='footer-link' href="#community">Know Us</a>
              <a className='footer-link' href="https://forms.gle/j4vwTTPyf6D7qzeY6">Student</a>
              <Link className='footer-link' to='/activities' target='_top' >Activities</Link>
            </div>

            <div className='footer-links-box'>
              <h3 className='footer-links-category-title'>Socials</h3>
              <a className='footer-link' href="https://www.linkedin.com/company/bharatanve/">LinkedIn</a>
              <a className='footer-link' href="https://discord.gg/xdvK5YEKTk">Discord</a>
              <a className='footer-link' href="https://twitter.com/Bharatanve">Twitter</a>
              <a className='footer-link' href="https://www.instagram.com/bharatanve/">Instagram</a>
            </div>
          </div>
          </div>

        <div className="footer-copyrights-container">
          <p className='copyrights'>© 2022, Bharatanve Ltd, All rights reserved.</p>
          <p className='privacy-policy'> <a className='terms-conditions' href="#t&c">Terms & Conditions</a> | <a className='privacy-policy' href="#privacy-policy">Privacy Policy</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer