import React from 'react'
import './terms.css';

const Terms = () => {
    return(
        <>
        <h1>Terms and condition of Bharatanve.</h1>
        </>
    )
}

export default Terms