import React from 'react'
import './ent.css';
import { Link } from 'react-router-dom';

import ent_img from "../../assets/entrepreneur-img.png";
import white_arrow from "../../assets/arrow-white.svg";

const Homeasent = () => {
  return (

    <div className="dark-window padding">
    <div className="flexbox">
      <div className="dark-illustration-bg hidden">
        <img
          className="illustration-img"
          src={ent_img}
          alt="illustration"
        />
      </div>

      <div className="content-text-box">
        <p className="dark-section-tag">ENTREPRENEUR</p>

        <h2 className="dark-section-heading">
          Being an{" "}
          <span className="dark-section-heading or-gradient-text">
            Entrepreneur
          </span>
        </h2>

        <p className="dark-section-para-1">
        Entrepreneurship is no less than an adventure and you as an seeker must be aware of your obstacles and must have knowledge on how to overcome them. At times, you will need maps, resources, assistance to venture your adventurous journey.
        </p>

        <p className="dark-section-para-2">
        That's where Bharatanve comes as a Google Maps, fulfilling your needs with better guidance, resources, assistance and platform to experiment.
        </p>

        <div className="button hidden">
          <Link to='/entrepreneurs' target='_top' className="button-text">
            Learn More.. <img src={white_arrow} alt="arrow" />
          </Link>
          <div className="button-underline"></div>
        </div>
      </div>
    </div>
  </div>

  )
}

export default Homeasent