import React from 'react';
import './ent-page-better-planning.css';

import ent_page_better_planning_img from '../../assets/ent-page-better-planning-img.png';

const Entpagebetterplanning = () => {
  return (
  <div className='window padding'>
    <div id='ent-page-better-planning-flexbox' className='flexbox'>

      <div id="ent-better-planning-img" className='illustration-bg-noshadow hidden'>
        <img className='illustration-img' src={ent_page_better_planning_img} alt="illustration" />
      </div>


      <div className='content-text-box'>

        <h2 className='section-heading'>Plan it <span className='section-heading or-gradient-text'>Better</span> </h2>

        <p className='section-para-1'>90% of all the startups fail due to improper planning and strategy development.</p>
  
        <p className='section-para-2'>A platform to learn from the experienced, guidance from the mentors is what young entrepreneurs need and we are here to help you with it.</p>
        
      </div>

    </div>

  </div>
  )
}

export default Entpagebetterplanning