import React from 'react'
import './students-explore-events.css';

import {Link} from 'react-router-dom';
import students_explore_events_banner from '../../assets/students-explore-events-banner.png';
import white_arrow from "../../assets/arrow-white.svg";

const Studentsexploreevents = () => {
  return (
    <div className='dark-window padding'>
      
      <div className='flexbox'>
        
        <div className="illustration-bg-nobox hidden">
            <img src={students_explore_events_banner} alt="illustration" className="illustration-img" />
        </div>

        <div className="content-text-box">
            <h2 className="dark-section-heading">Explore at <span className='dark-section-heading or-gradient-text'>Events</span></h2>

            <p className="dark-section-para-1">
                Eliminating the boundaries of your imagination, and helping you to bring them in the real world.
            </p>

            <p className='dark-section-para-1'>
                With your membership at Bharatanve, you get most of the benefits as a Student through the events and programs that are focused to provide you the exposure which traditional learning system can't provide.
            </p>

            <p className="dark-section-para-2">
                Technology, Entrepreneurship, Hackathons, Internship Arena, Gallery and lots of other events to explore which boost your Academic as well as Career growth.
            </p>

            <p className="dark-button-desc">
            {" "}
            📣 Get Notified about the upcoming Events 
            </p>

            <div className="button hidden">
            <a className="button-text" href="https://forms.gle/W16Nop5542YMasvf6" >
              Get Notified <img src={white_arrow} alt="arrow" />
            </a>
            <div className="button-underline"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Studentsexploreevents
