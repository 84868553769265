import React from 'react'
import './entrepreneurs-page.css';
import { Entpagebetterenvironment, Entpagebuildteam, Entpagehero, Entpagencg } from '../../sections';

const Entrepreneurs = () => {
    return(
        <>
        <Entpagehero />
        <Entpagebetterenvironment />
        <Entpagebuildteam />
        <Entpagencg />
        </>
    )
}

export default Entrepreneurs