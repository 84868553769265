import React from 'react'
import './sign-up.css';

const Signup = () => {
    return(
        <>
        <h1>Register for community page</h1>
        </>
    )
}

export default Signup