import React from 'react'
import './student.css';

import {Link} from 'react-router-dom';
import student_img from "../../assets/student-img.png";
import white_arrow from "../../assets/arrow-white.svg";


const Homeasstudent = () => {
  return (
    <div id='student' className="dark-window padding">
      
      <div className="flexbox">
        <div className="dark-illustration-bg hidden">
          <img
            className="illustration-img"
            src={student_img}
            alt="illustration"
          />
        </div>

        <div className="content-text-box">
          <p className="dark-section-tag">STUDENT</p>

          <h2 className="dark-section-heading">
            Being a{" "}
            <span className="dark-section-heading or-gradient-text">
              Student
            </span>
          </h2>

          <p className="dark-section-para-1">
            Ever wondered what you can do with the time left after college hours ? From Startups to huge MNC's are looking out for people who could help them with their works. Well you can be the one if you have the right skills.
          </p>

          <p className="dark-section-para-2">
            Bharatanve provides such PROs the platform to learn, build and grow with such Startups and MNCs.
          </p>

          <div className="button hidden">
            <Link to="/students" target='_top' className="button-text" >
              Become a Pro <img src={white_arrow} alt="arrow" />
            </Link>
            <div className="button-underline"></div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Homeasstudent