import React from 'react';
import './students-cpr-model.css';
import students_cpr_model_img from "../../assets/students-cpr-model-img.png"

const Studentscprmodel = () => {
    return(

        <div id='students-cpr-model' className='window padding'>
        <div className='flexbox'>

      <div className='illustration-bg-noshadow hidden'>
        <img id='students-cpr-model-img' className='illustration-img' src={students_cpr_model_img} alt="illustration" />
      </div>


      <div className='content-text-box'>

        <h2 className='section-heading'>The <span className='section-heading or-gradient-text'>CPR</span> Model</h2>

        <p className='section-para-1'>Learning something just for the sake of exams is not good for your, nor your future career. And we don't want you to regret for studying this way your entire life.</p>
  
        <p className='section-para-2'>Bharatanve brings you the CPR {'( Clarity, Purpose and Resources )'} Model that will make sure that you "Zero" regrets in your college days.</p>

        <table className='section-table'>

                <tbody className='section-table-row'>
                    <tr>
                        <td className='section-table-heading-cell'>Clarity<span className="or-gradient-text">.</span></td>
                        <td className='section-table-value-cell'>Clarity brings peace of mind to you so that you can focus on what is important to you use your time productively. We provide that clarity to you.</td>
                    </tr>
                </tbody>

                <tbody className='section-table-row'>
                    <tr>
                        <td className='section-table-heading-cell'>Purpose<span className="or-gradient-text">.</span></td>
                        <td className='section-table-value-cell'>There is a reason behind everything that happens. But most of the us don't even know the reason behind why we should learn something. We help you to get a clear purpose behind everything you learn and also help you find the purpose for your life.</td>
                    </tr>
                </tbody>

                <tbody className='section-table-row'>
                    <tr>
                        <td className='section-table-heading-cell'>Resources<span className="or-gradient-text">.</span></td>
                        <td className='section-table-value-cell'>Now after you achieving a clarity and purpose in your learning days, you also must need resources to learn what you want right. Bharatanve. provides you with tons of best of the best resources that help you learn whatever you desire.</td>
                    </tr>
                </tbody>

        </table>

      </div>

    </div>

  </div>
  
    )
}

export default Studentscprmodel