import React from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import {
  Home,
  Students,
  Entrepreneurs,
  Privacy,
  Terms,
  Signup,
  Error404,
  Layout,
  Activities,
} from "./containers";

const App = () => {
  return (
    
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/students" element={<Students />} />

          <Route path="/entrepreneurs" element={<Entrepreneurs />} />

          <Route path="/activities" element={<Activities />} />

          <Route path="/sign-up" element={<Signup />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />

          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>

);
};




  
    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     console.log(entry);
    //     if (entry.isIntersecting) {
    //       entry.target.classList.add("show");
    //     } else {
    //       entry.target.classList.remove("show");
    //     }
    //   });
    // });
    
    // const hiddenElements = document.querySelectorAll(".hidden");
    // hiddenElements.forEach((el) => observer.observe(el));
    


    
    export default App;
    