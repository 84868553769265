import React from 'react';
import './students-think-out-of-box.css';
import students_think_out_of_box_img from "../../assets/students-think-out-of-box-img.png";

const Studentsthinkbox =() => {
    return(
        <div className='dark-window padding'>

            <div className='flexbox'>

                <div className='illustration-bg-nobox hidden'>
                    <img
                    className='illustration-img' 
                    src={students_think_out_of_box_img} alt="illustration" />
                </div>

                <div className="content-text-box">
                    <h2 className="dark-section-heading">Think out of the <span className='dark-section-heading or-gradient-text'>Box</span></h2>

                    <p className="dark-section-para-1">Let that curious mind explore...</p>

                    <p className="dark-section-para-1">Providing you a open environment where you can ask anything you can think of and get the answers and resources from experts to understand and learn.</p>

                    <p className="dark-section-para-2">We promote the way of learning you actually need in your college.</p>
                </div>
            </div>
        </div>
    )
}

export default Studentsthinkbox