import React from 'react';
import './ent-page-build-team.css';
import ent_page_build_team_img from '../../assets/ent-page-build-team-img.png';

const Entpagebuildteam = () => {
  return (
    <div className="dark-window padding">
      
        <div className="flexbox">
          <div className="illustration-bg-nobox hidden">
            <img
              className="illustration-img"
              src={ent_page_build_team_img}
              alt="illustration"
            />
          </div>
  
          <div className="content-text-box">
  
            <h2 className="dark-section-heading">
              Building a {" "}
              <span className="dark-section-heading or-gradient-text">
                Team
              </span>
            </h2>
  
            <p className="dark-section-para-1">
              Founding team of any startup is the foundation for its success.
            </p>
  
            <p className="dark-section-para-2">
              Connect with people who are as enthusiastic and knowledgable as you are and build a team whose members are complimentary one another.
            </p>


          </div>
        </div>
      </div>
  )
}

export default Entpagebuildteam
